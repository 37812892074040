export const appHead = {"meta":[{"name":"viewport","content":"width=device-width, initial-scale=1"},{"charset":"utf-8"}],"link":[{"href":"//cdn-images.mailchimp.com/embedcode/classic-061523.css","rel":"stylesheet","type":"text/css"},{"rel":"icon","type":"image/png","href":"/favicon-96x96.png","sizes":"96x96"},{"rel":"icon","type":"image/svg+xml","href":"/favicon.svg"},{"rel":"shortcut icon","href":"/favicon.ico"},{"rel":"apple-touch-icon","sizes":"180x180","href":"/apple-touch-icon.png"},{"rel":"manifest","href":"/site.webmanifest"}],"style":[],"script":[{"id":"cookieyes","src":"https://cdn-cookieyes.com/client_data/fbe9066f39b06df083d0a3a4/script.js","type":"text/javascript","defer":true},{"src":"//s3.amazonaws.com/downloads.mailchimp.com/js/mc-validate.js","type":"text/javascript","defer":true},{"innerHTML":"\n            (function($) {\n              window.fnames = new Array(); \n              window.ftypes = new Array();\n              fnames[0]='EMAIL'; ftypes[0]='email'; \n              fnames[1]='FNAME'; ftypes[1]='text';\n            }(jQuery));\n            var $mcj = jQuery.noConflict(true);\n          ","type":"text/javascript","body":true},{"hid":"gtm-script","innerHTML":"(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':\n            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],\n            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=\n            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);\n            })(window,document,'script','dataLayer','GTM-KGPG6SCP');","type":"text/javascript"}],"noscript":[{"hid":"gtm-noscript","innerHTML":"<iframe src=\"https://www.googletagmanager.com/ns.html?id=GTM-KGPG6SCP\" \n            height=\"0\" width=\"0\" style=\"display:none;visibility:hidden\"></iframe>"}],"__dangerouslyDisableSanitizersByTagID":{"mc-validate":["innerHTML"],"gtm-script":["innerHTML"],"gtm-noscript":["innerHTML"]}}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const appRootAttrs = {"id":"__nuxt"}

export const appTeleportTag = "div"

export const appTeleportId = "teleports"

export const appTeleportAttrs = {"id":"teleports"}

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const cookieStore = true

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const devLogs = false

export const nuxtLinkDefaults = {"componentName":"NuxtLink","prefetch":true,"prefetchOn":{"visibility":true}}

export const asyncDataDefaults = {"value":null,"errorValue":null,"deep":true}

export const resetAsyncDataToUndefined = true

export const nuxtDefaultErrorValue = null

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false

export const appId = "nuxt-app"

export const outdatedBuildInterval = 3600000

export const multiApp = false

export const chunkErrorEvent = "vite:preloadError"

export const crawlLinks = true